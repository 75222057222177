* {
  /* font-family: Helvetica, sans-serif; */
  font-family: "Poppins", "SF Pro Display", sans-serif;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3 {
  font-weight: 500;
}
:root {
  --primary-color: #1b3466;
  /* --primary-color: #9cb84f; */
}
body {
  height: 100%;
}
.color-page {
  background-color: var(--primary-color);
}
/* .color-page input,
.color-page .MuiInputLabel-root {
  color: white;
} */

.g-btn {
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}
.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.card {
  border: 1px solid hsla(210, 8%, 95%, 0.75);
  border-radius: 0.15em;
  display: inline-block;
  height: 1.5sem;
  text-align: center;
  width: 1em;
}
.yellow {
  background: yellow;
}
.red {
  background: red !important;
}
.inverted-border-radius {
  position: relative;
  /* height: 100px; */
  width: 325px;
  border-radius: 0px 0px 0 0;
}

.inverted-border-radius::before {
  content: "";
  position: absolute;

  background-color: transparent;
  bottom: -50px;
  height: 50px;
  width: 25px;
  border-top-left-radius: 25px;
  box-shadow: 0 -25px 0 0 var(--primary-color);
}
.inverted-border-radius::after {
  content: "";
  position: absolute;

  background-color: transparent;
  bottom: -50px;
  right: 0;
  height: 50px;
  width: 25px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 var(--primary-color);
}
svg {
  margin: 0 !important;
}
.swipeableDrawer > .MuiPaper-root {
  height: calc(80% - 64px);
  overflow: visible;
}